import React from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from '../../components/layout'
import { ApplyAndWhatsNextSections, PositionIcon } from '../../components/pages/CareersPage'
import { Nav } from '../../components/nav'
import { LdJsonJobPosting, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const CareerPositionPage = () => (
  <Layout className="bg-primary-1 text-primary-3">
    <Analytics />
    <SEO
      title="Senior Web Frontend Developer with React"
      pageUrl="/careers/seniorreact/"
      type="article"
      cardImage="/img/tumbacardjobs1.png"
      description="If you have an eye for details, you cannot stand mediocre UX, you never give up until the presentation is smooth and polished, Let's Talk!"
    />
    <LdJsonJobPosting
      title="Senior Web Frontend Developer with React"
      description="If you have an eye for details, you cannot stand mediocre UX, you never give up until the presentation is smooth and polished, Let's Talk!"
      publishDate="2022-02-10T08:18:09.000Z"
      minSalary="4500.00"
      maxSalary="6000.00"
    />
    <MainWithMenu className="relative grid">
      <Header className="z-20 pt-6 pb-24">
        <HeaderLink className="text-primary-2" />
        <Link to="/careers/">
          <div className="py-12 text-left">
            <h1 className="py-4 text-4xl lg:text-5xl text-primary-3 text-shadow">Careers</h1>
          </div>
        </Link>
      </Header>
      <Deco className="z-10 bg-primary-2" decoType={2} before after gridRow={2} />
      <Section className="z-20 lg:mr-48" gridRow={2}>
        <PositionIcon className="w-12 sm:w-20 text-primary-1 fill-current" />
        <h2 className="text-3xl lg:text-4xl text-primary-1">
          Senior Web Frontend Developer <br /> with React
        </h2>
        <div className="mt-4 mb-16 text-lg lg:text-xl tracking-normal">
          <p className="sm:mt-6 font-normal">
            You know that having good and robust business logic is not enough. The data presentation and good UX are as important, if not even more, for a product to succeed. If{' '}
            <strong className="text-primary-1 font-normal">you have an eye for details</strong>, you cannot stand mediocre UX, you never give up until the presentation is smooth and polished.
          </p>
          <p className="sm:mt-6 font-normal">
            Then <strong className="text-primary-1 font-normal">let's talk</strong> with common minded peers and explore together how deep the rabbit hole goes and does it lead to wonderland!
          </p>
          <p className="sm:mt-6">
            What we'd like to do <strong className="text-primary-1">together</strong>:
          </p>
          <ul className="pl-10 mt-2 list-disc font-normal">
            <li>Automate challenging workflows to increase time and money spent in a more meaningful way.</li>
            <li>Integrate systems that were never thought of being able to talk to each other.</li>
            <li>Optimise flows using machine learning.</li>
            <li>Apply modern Web technologies for a prettier presentation and great UX.</li>
          </ul>
          <p className="sm:mt-6 font-normal">
            <em>What we are primarily looking for is a track record of building things that work and passion to make a better product.</em>
          </p>
        </div>
        <div className="grid gap-12 grid-cols-1 lg:grid-cols-2">
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Main Responsibilities</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Build and implement</strong> state-of-the-art frontend features by writing accessible, performant, maintainable and well-tested code, following the latest best practices.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Assist</strong> with high-level analysis and design, able to identify gaps in requirements and proactively seek clearance and refinement.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Break down features</strong> into easy-to-estimate tasks and take them through all its stages in a predictive manner with attention to details.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Identify opportunities</strong> for improving both the software and the development methodologies and procedures and continuously discover, evaluate and implement new technologies in order to
                  maximize development efficiency.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="text-xl lg:text-2xl text-primary-1">Core Requirements</h3>
            <div className="mt-4 text-lg lg:text-xl font-normal tracking-normal">
              <ul className="pl-10 list-disc">
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Proficient</strong> in React and the modern Web technologies and principles.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">5+ years</strong> of frontend development, most if not all of it, using JavaScript and TypeScript to build Single Page Applications.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">3+ years</strong> using React.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good knowledge</strong> how the WWW works and the different types of browser to server communication.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good knowledge</strong> in browser compatibility.
                </li>
                <li className="mt-4">
                  <strong className="text-primary-1 font-normal">Good working knowledge</strong> in writing and maintaining automated UI tests.
                </li>
                <li className="mt-4">A sense for good design and great UX while maintaining a strong focus on performance, semantic markup, accessibility and security.</li>
                <li className="mt-4">Self-organised, proactive and good communicator.</li>
                <li className="mt-4">Able to look through the customer lens.</li>
                <li className="mt-4">Likes to help, mentor and guide other team members.</li>
                <li className="mt-4">
                  Some backend experience in creating or maintaining RESTful APIs is <strong className="text-primary-1 font-normal">a plus</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Section>
      <ApplyAndWhatsNextSections position="Senior Web Frontend Developer with React" active={false} />
      <Nav gridRow={7} className="z-20 text-primary-2" />
    </MainWithMenu>
  </Layout>
)

export default CareerPositionPage
